import { useState } from 'react'

import { IdsAccordion } from '@ids/react'
import analytics from '@mobi/libraries/analytics'

import './FAQ.scss'
import { CONTENT } from './constants'

const FAQ = () => {
  const [open, setOpen] = useState(null)
  const handleOpen = (idx) => (open === idx ? setOpen(null) : setOpen(idx))

  return (
    <section className="lp-faqq">
      <div className="container">
        <h2 className="lp-faqq__title">Ficou com dúvidas?</h2>
        <div className="lp-faqq__container ids-card">
          {CONTENT.map(({ answers, question }, idx) => (
            <div
              key={question}
              data-testid={`faq-item-${idx}`}
              onClick={() => {
                analytics.track('buttonClick', {
                  currentPage: 'home-lp-shopping',
                  detail: 'click-faq',
                  customLayer: {
                    description: question,
                    position: idx
                  }
                })
              }}
            >
              <IdsAccordion
                open={open === idx}
                setOpen={() => handleOpen(idx)}
                header={question}
              >
                {answers}
              </IdsAccordion>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FAQ
