import { IdsMainButton } from '@ids/react'
import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { scrollToElement, setFocus } from 'utils'

import Img from 'components/Img/index'

import './Hiring.scss'

const Hiring = () => {
  const isDesktop = useMediaQuery('tablet')

  return (
    <section className="lp-hiring">
      <div className="container">
        <h2 className="lp-hiring__title">
          Contratação fácil, rápida e com a segurança Itaú
        </h2>
        <ul className="lp-hiring__list">
          <li className="lp-hiring__item">
            <span className="lp-hiring__item-title">
              1. Conte o que você precisa
            </span>
            <p className="lp-hiring__item-description">
              Responda as perguntas rápidas
            </p>
          </li>
          <li className="lp-hiring__item">
            <span className="lp-hiring__item-title">
              2. Veja a nossa seleção
            </span>
            <p className="lp-hiring__item-description">
              Vamos separar as melhores ofertas para você{' '}
            </p>
          </li>
          <li className="lp-hiring__item">
            <span className="lp-hiring__item-title">
              3. Faça tudo de forma digital
            </span>
            <p className="lp-hiring__item-description">
              Contrate seu empréstimo e siga as instruções para finalizar a
              proposta
            </p>
          </li>
        </ul>
        <IdsMainButton
          data-testid="hiring"
          onClick={() => {
            analytics.track('buttonClick', {
              currentPage: 'home-lp-shopping',
              detail: 'click-3-passos-simular-agora'
            })
            scrollToElement('#hero')
            setFocus('#cpf')
          }}
          aria-label={`simular empréstimo contratação rápida agora`}
        >
          Simular agora
        </IdsMainButton>
      </div>
      <Img
        file={isDesktop ? `hiring-desktop` : `hiring-mobile`}
        className="lp-hiring__image"
      />
    </section>
  )
}

export default Hiring
