import { useState } from 'react'

import { http } from '@itau-loans-www/shopping/src/services'
import { goTo } from 'utils'

import { DDPageView } from 'utils/datadog/events'

export const useHero = ({ changeLeadData }) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const onSubmit = ({ cpf }) => {
    setIsButtonLoading(true)
    changeLeadData({ cpf })

    DDPageView({ pageName: 'createLeadLP' })

    http.proposal
      .createLead({ cpf })
      .then(() => goTo('/proposta/nome'))
      .catch(() => setIsButtonLoading(false))
  }

  return {
    onSubmit,
    isButtonLoading
  }
}
