export const CONTENT = [
  {
    question: 'Como funcionam os empréstimos do Itaú?',
    answers:
      'Dentro dos seus limites de pré-aprovado você pode escolher o valor do empréstimo e a quantidade de parcelas. A partir daí você nos envia sua proposta e até pode receber o dinheiro na hora na sua conta.'
  },
  {
    question: 'Eu preciso ser correntista para contratar?',
    answers:
      'Não, temos produtos e serviços de crédito disponíveis para qualquer pessoa.'
  },
  {
    question: 'Como contratar?',
    answers:
      'Você poderá contratar nossos produtos realizando uma simulação neste site ou dentro dos aplicativos Itaú.'
  },
  {
    question: 'Quais são as taxas de juros?',
    answers:
      'Temos produtos com taxas personalizadas que podem variar dependendo do valor e prazo solicitado. Para saber a sua taxa, simule e descubra sua oferta.'
  },
  {
    question: 'É possível fazer um empréstimo estando negativado?',
    answers:
      'O bom histórico financeiro é levado em consideração no momento da análise, mas não se preocupe, pois estamos aqui também para te ajudar a renegociar suas dívidas e aumentar sua chance de aprovação.'
  },
  {
    question: 'Onde conseguir um empréstimo?',
    answers:
      'Aqui temos ofertas de empréstimo para correntistas e não correntistas, de forma rápida e prática, além de contar com toda a segurança e transparência da marca Itaú. Para saber quais ofertas temos disponíveis para você, realize uma simulação e descubra.'
  }
]
