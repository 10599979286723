import { Helmet } from 'react-helmet'

import { CONTENT as FAQCONTENT } from '@itau-loans-www/shopping/src/pages/Home/components/FAQ/constants'

const faqStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: FAQCONTENT.map((faq) => ({
    '@type': 'Question',
    name: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.answers
    }
  }))
}

const LandingPageSEO = () => (
  <>
    <Helmet>
      <meta property="og:site_name" content="Itaú" />
      <script type="application/ld+json">{`${JSON.stringify(
        faqStructuredData
      )}`}</script>
    </Helmet>
    <div itemscope itemtype="https://schema.org/WebSite">
      <meta
        itemprop="url"
        content="https://www.shopping.emprestimo.itau.com.br/"
      />
      <meta itemprop="name" content="Itaú" />
    </div>
  </>
)

export default LandingPageSEO
