import { useEffect } from 'react'

import Layout from '@itau-loans-www/shopping/src/layouts/LandingPage'
import analytics from '@mobi/libraries/analytics'
import { storage, isClient } from 'utils'

import FAQ from './components/FAQ/index'
import Hero from './components/Hero'
import HiredSolutions from './components/HiredSolutions'
import Hiring from './components/Hiring/index'
import Product from './components/Product/index'
import Solutions from './components/Solutions'
import { SOLUTIONS } from './constants'

const Shopping = () => {
  useEffect(() => {
    if (isClient()) {
      storage.session.clear()
    }
    analytics.track('pageLoad', { currentPage: 'home-lp-shopping' })
  }, [])

  return (
    <Layout>
      <Hero />
      <Solutions />
      <HiredSolutions />
      {SOLUTIONS.map((solution) => (
        <Product key={solution.title} {...solution} />
      ))}
      <Hiring />
      <FAQ />
    </Layout>
  )
}

export default Shopping
