import { Header, SimulateButton } from '@itau-loans-www/shopping/src/components'
import classnames from 'classnames'

import MainLayout from '../Main'
import SEO from './components/SEO'
import './landing-page.scss'

export default ({ children, className, ...props }) => {
  const classNames = classnames('landing-layout', {
    [className]: className
  })

  const HeaderDs = () => <Header isLanding={true} />

  return (
    <>
      <SEO />
      <div {...props} className={classNames}>
        <MainLayout Header={HeaderDs} hasFooter hasSimulateBtn={true}>
          <main className="landing-content">{children}</main>
          <SimulateButton />
        </MainLayout>
      </div>
    </>
  )
}
