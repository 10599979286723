export const SOLUTIONS = [
  {
    id: 'nao-correntista',
    title: 'Pra quem não tem conta',
    detail: 'click-secao-ncor-simular-agora',
    filterDetail: 'click-secao-ncor-mais-emprestimos',
    variant: 'gray',
    description:
      'Precisando de dinheiro mas ainda não tem conta no Itaú? Conheça as soluções disponíveis, simule agora e descubra a melhor oferta.',
    cards: [
      {
        title: 'Empréstimo consignado em qualquer conta',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Até R$ 250 mil e 120 meses pra pagar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Receba em qualquer banco'
          }
        ]
      },
      {
        title: 'Crédito pessoal no seu cartão',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Até R$ 40 mil e 48 meses pra pagar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Pagamento na fatura do seu cartão'
          }
        ]
      }
    ]
  },
  {
    id: 'correntista',
    title: 'Pra quem já tem conta',
    detail: 'click-secao-corr-simular-agora',
    filterDetail: 'click-secao-corr-mais-emprestimos',
    variant: 'white',
    description:
      'Pensando em tirar os planos do papel? Conheça soluções com as parcelas mais leves pra pagar, simule agora e descubra a melhor oferta.',
    cards: [
      {
        title: 'Crédito pessoal Itaú',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Até 3 meses pra começar a pagar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Receba o dinheiro na hora'
          }
        ]
      },
      {
        title: 'Empréstimo consignado',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Ótimas taxas e até 72 meses para pagar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Pagamento descontado do seu salário'
          }
        ]
      },
      {
        title: 'Crédito com garantia de imóvel',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Até 20 anos para quitar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Empréstimo a partir de 30 mil reais'
          }
        ]
      },
      {
        title: 'Antecipação Saque-Aniversário FGTS',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Até 97% do valor do seu saldo'
          },
          {
            icon: 'consorcio_outline',
            text: 'Desconto anual direto do seu FGTS'
          }
        ]
      },
      {
        title: 'Renove seu crédito consignado',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Tenha mais tempo para pagar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Pagamento descontado direto do seu salário'
          }
        ]
      }
    ]
  },
  {
    id: 'tempo-para-pagar',
    title: 'Com mais tempo pra pagar',
    detail: 'click-secao-tempo-pagar-simular-agora',
    filterDetail: 'click-secao-tempo-pagar-mais-emprestimos',
    variant: 'gray',
    description:
      'Pensando em tirar os planos do papel? Conheça soluções com as parcelas mais leves pra pagar, simule agora e descubra a melhor oferta.',
    cards: [
      {
        title: 'Empréstimo consignado',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Ótimas taxas e até 72 meses pra pagar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Pagamento descontado do seu salário'
          }
        ]
      },
      {
        title: 'Crédito com garantia de imóvel',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Até 20 anos pra pagar seu empréstimo'
          },
          {
            icon: 'consorcio_outline',
            text: 'Taxas a partir de 1,56% ao mês'
          }
        ]
      },
      {
        title: 'Antecipação Saque-Aniversário FGTS',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Até 97% do valor do seu saldo'
          },
          {
            icon: 'consorcio_outline',
            text: 'Desconto anual direto do seu FGTS'
          }
        ]
      },
      {
        title: 'Empréstimo consignado em qualquer conta',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Até R$ 250 mil e 120 meses para pagar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Receba na sua conta de qualquer banco'
          }
        ]
      },
      {
        title: 'Renove seu crédito consignado',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Tenha mais tempo para pagar'
          },
          {
            icon: 'consorcio_outline',
            text: 'Pagamento descontado direto do seu salário'
          }
        ]
      }
    ]
  },
  {
    id: 'reorganizar-dividas',
    title: 'Pra reorganizar minhas dívidas',
    detail: 'click-secao-dividas-simular-agora',
    filterDetail: 'click-secao-dividas-mais-emprestimos',
    variant: 'white',
    description:
      'Estamos com você pra te ajudar a encontrar a melhor solução de pagamento das suas dívidas, simule agora e descubra a melhor oferta.',
    cards: [
      {
        title: 'Reorganize suas dívidas com a Recovery',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Descontos de até 80% da dívida'
          },
          {
            icon: 'consorcio_outline',
            text: 'Dívidas de sob gestão da Recovery '
          }
        ]
      },
      {
        title: 'Reorganize suas dívidas com o Itaú',
        benefits: [
          {
            icon: 'calendario_outline',
            text: 'Limpe seu nome em até 5 dias'
          },
          {
            icon: 'consorcio_outline',
            text: 'Reorganize suas finanças, prazo de até 60 meses'
          }
        ]
      }
    ]
  }
]
