export const LIST_SOLUTIONS = [
  {
    image: 'hired-solutions-1',
    alt: '',
    description: 'Pra quem tem saldo parado no FGTS',
    title: 'Antecipação Saque-Aniversário',
    utm_source: 'solucoes-mais-contratadas-fgts',
    track_detail: 'click-card-produto-fgts',
    benefits: [
      {
        icon: 'juros',
        text: 'Antecipe até 7 parcelas'
      },
      {
        icon: 'celular_pre_pago_outline',
        text: 'Desconto anual direto do seu FGTS'
      }
    ]
  },
  {
    image: 'hired-solutions-2',
    alt: '',
    description: 'Para quem não tem conta',
    title: 'Empréstimo consignado em qualquer conta',
    utm_source: 'solucoes-mais-contratadas-consignado',
    track_detail: 'click-card-produto-consignado',
    benefits: [
      {
        icon: 'confirmacao',
        text: 'Receba em qualquer banco'
      },
      {
        icon: 'consorcio_outline',
        text: 'Até R$ 250 mil e 120 meses pra pagar'
      }
    ]
  },
  {
    image: 'hired-solutions-3',
    alt: '',
    description: 'Pra quem já tem conta',
    title: 'No consórcio Itaú, você encontra',
    utm_source: 'solucoes-mais-contratadas-consorcio',
    track_detail: 'click-card-produto-consorcio',
    benefits: [
      {
        icon: 'juros',
        text: 'Sem entrada e sem juros'
      },
      {
        icon: 'calendario_outline',
        text: 'Você escolhe o valor que pretende pagar'
      }
    ]
  }
]
