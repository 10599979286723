export const BANNERS = [
  {
    title: 'Orçamento tá apertado?',
    description: 'O Itaú vai te ajudar e você nem precisa ter conta'
  },
  {
    title: 'Feito para ser simples',
    description: 'O Itaú compara e mostra  a melhor oferta pra você',
    file: 'banner-1'
  },
  {
    title: 'Feito para emergências',
    description: 'Dinheiro que pode cair na sua conta em minutos'
  },
  {
    title: 'Feito para os seus planos',
    description: 'Opções com até 15 anos pra pagar',
    file: 'banner-3'
  },
  {
    title: 'Feito para quem precisa',
    description: 'Simule já e descubra sua oferta'
  }
]

export const SETTINGS_GLIDE = {
  autoplay: 5000,
  hoverpause: true,
  gap: 0
}
