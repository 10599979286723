import { IdsIcon } from '@ids/react'
import analytics from '@mobi/libraries/analytics'

import './Solutions.scss'

const Solutions = () => {
  return (
    <section className="lp-solutionz">
      <div className="container">
        <h3 className="lp-solutionz__sub-title">
          O Itaú compara e mostra a melhor oferta
        </h3>
        <h2 className="lp-solutionz__title">
          Conheça as soluções que temos pra você
        </h2>
        <ul className="lp-solutionz__list">
          <li className="lp-solutionz__item">
            <a
              role="button"
              className="lp-solutionz__button ids-card"
              href="#nao-correntista"
              onClick={() =>
                analytics.track('buttonClick', {
                  currentPage: 'home-lp-shopping',
                  detail: 'click-seletor-nao-correntista'
                })
              }
            >
              <IdsIcon className="ids-color-text-highlight">
                celular_pre_pago_outline
              </IdsIcon>
              Para quem não tem conta
              <IdsIcon className="lp-solutionz__icon-arrow">
                seta_direita
              </IdsIcon>
            </a>
          </li>

          <li className="lp-solutionz__item">
            <a
              role="button"
              className="lp-solutionz__button ids-card"
              href="#correntista"
              onClick={() =>
                analytics.track('buttonClick', {
                  currentPage: 'home-lp-shopping',
                  detail: 'click-seletor-correntista'
                })
              }
            >
              <IdsIcon className="ids-color-text-highlight">
                conta_corrente_outline
              </IdsIcon>
              Pra quem já tem conta
              <IdsIcon className="lp-solutionz__icon-arrow">
                seta_direita
              </IdsIcon>
            </a>
          </li>

          <li className="lp-solutionz__item">
            <a
              role="button"
              className="lp-solutionz__button ids-card"
              href="#tempo-para-pagar"
              onClick={() =>
                analytics.track('buttonClick', {
                  currentPage: 'home-lp-shopping',
                  detail: 'click-seletor-mais-tempo-pra-pagar'
                })
              }
            >
              <IdsIcon className="ids-color-text-highlight">
                calendario_outline
              </IdsIcon>
              Com mais tempo para pagar
              <IdsIcon className="lp-solutionz__icon-arrow">
                seta_direita
              </IdsIcon>
            </a>
          </li>

          <li className="lp-solutionz__item">
            <a
              role="button"
              className="lp-solutionz__button ids-card"
              href="#reorganizar-dividas"
              onClick={() =>
                analytics.track('buttonClick', {
                  currentPage: 'home-lp-shopping',
                  detail: 'click-seletor-dividas'
                })
              }
            >
              <IdsIcon className="ids-color-text-highlight">
                credito_outline
              </IdsIcon>
              Pra reorganizar minhas dívidas
              <IdsIcon className="lp-solutionz__icon-arrow">
                seta_direita
              </IdsIcon>
            </a>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Solutions
